import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Reference';
import { RootState } from 'app/AppStore';
import { getDiscountTextStyle } from 'components/Discount';
import { getEstimateById, setEstimateReferenceDiscountMargins } from 'domains/estimate/Estimate.store';
import { getDiscount } from 'domains/references';
import { calculateEstimateDiscountPrice } from 'pages/EstimatePage/TableSection/EstimateGaragePrice';
import { Flex, Text } from 'UI';
import { getData, textFormatter } from 'utils';

export const getMarginIncludingPromotion = (
  discount: ReferenceDiscount | undefined,
  row: Reference,
): {
  margin: number;
  marginPercentage: number;
} => {
  if (!discount) {
    return { margin: Number(row.margin) || 0, marginPercentage: parseFloat(row.marginPercentage || '0') };
  }
  const garagePriceWithDiscount = calculateEstimateDiscountPrice(
    discount,
    row.unitPrice,
    row.garagePrice,
    row.discountRate,
  );
  const totalGaragePrice = garagePriceWithDiscount * row.quantity;
  const margin = Number(row.priceVatExcluded) - totalGaragePrice;
  const marginPercentage = (margin / Number(row.priceVatExcluded)) * 100;

  return { margin, marginPercentage };
};

export const EstimateGarageMargin = ({
  estimateId,
  row,
  currency,
  isInvalid,
}: {
  estimateId: string;
  row: Reference;
  currency: string;
  isInvalid?: boolean;
}) => {
  const dispatch = useDispatch();
  const estimateData = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const discount = useSelector((state: RootState) =>
    getDiscount(state, row.referenceNumber, estimateData?.garageId, estimateData?.clientContact.tresorCode),
  );
  const { margin, marginPercentage } = getMarginIncludingPromotion(discount, row);
  useEffect(() => {
    if (discount) {
      dispatch(
        setEstimateReferenceDiscountMargins({
          referenceNumber: row.referenceNumber,
          estimateId,
          marginDiscount: margin - Number(row.margin),
        }),
      );
    }
  }, [dispatch, estimateId, margin, row, discount]);

  return (
    <Flex direction={'column'} justify={'flex-end'} align={'center'} minWidth={70}>
      <Text type={isInvalid ? 'light_14_bold_red' : 'light_14'} displayStyle={getDiscountTextStyle(discount)}>
        {textFormatter.formatCurrency(margin, currency)}
      </Text>
      <Text type={isInvalid ? 'light_14_red' : 'light_14'} displayStyle={getDiscountTextStyle(discount)}>
        {`${marginPercentage.toFixed(0)}%`}
      </Text>
    </Flex>
  );
};
