import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { isEmpty } from 'fast-glob/out/utils/string';
import { TFunction } from 'i18next';
import moment from 'moment';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DMSImportIcon } from 'assets/icons';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { fetchVehicleFunction } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { useFetchEstimateHistory } from 'domains/estimate/Estimate.requests';
import {
  cancelEstimateDeletion,
  deleteEstimateRequest,
  fetchEstimateHistoryRequest,
  getEstimateHistorySearch,
  getEstimateHistorySearchStatus,
  resetEstimateHistory,
  setCurrentEstimateId,
  setEstimateHistorySearch,
} from 'domains/estimate/Estimate.store';
import { getAvailableDMSService } from 'domains/user';
import { theme } from 'styles';
import { CenterFlex, Flex, Icon, InfiniteScroll, MarginBox, NotificationLink, notifyTop, TrashAltButton } from 'UI';
import { getCondArrayItem, isLoading, LOADING, textFormatter } from 'utils';
import { SHistoryTab, SSearchInput, STable } from './EstimatePage.styled';

const historyTableColumns = (
  t: TFunction,
  deleteEstimate: (estimateId: string) => void,
  isDMSAvailable: boolean | typeof LOADING,
  isB2B: boolean,
) => {
  const b2cHistoryColumns = [
    {
      title: t('common.creation_date', 'Creation date'),
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: function renderCreationDate(creationDate: string) {
        const date = new Date(creationDate);
        if (!moment(date).isValid()) return;
        return <CenterFlex>{textFormatter.formatDate(date)}</CenterFlex>;
      },
    },
    {
      title: t('common.creator_name', 'Estimated By'),
      dataIndex: 'creatorName',
      key: 'creator',
      render: function renderCreatorName(creatorName: string) {
        return <CenterFlex>{`${creatorName ?? ''}`}</CenterFlex>;
      },
    },
    {
      title: t('common.client_name', 'Client name'),
      dataIndex: 'clientName',
      key: 'client',
      render: function renderClientName(clientName: string | undefined) {
        return <CenterFlex>{clientName}</CenterFlex>;
      },
    },
    {
      title: t('catalog.vehicle.brand_model', 'Brand/Model'),
      dataIndex: 'vehicle',
      key: 'brandModel',
      render: function renderBrandModel(vehicle?: VehicleDetail) {
        if (!vehicle) {
          return <CenterFlex>-</CenterFlex>;
        }
        return <CenterFlex>{vehicle.vehicleBrand + ' ' + vehicle.model ?? ''}</CenterFlex>;
      },
    },
    {
      title: t('catalog.vehicle.vin_or_vrn', 'VIN or VRN'),
      dataIndex: 'vehicle',
      key: 'vinOrVrn',
      render: function renderVinOrVrn(vehicle?: VehicleDetail) {
        if (!vehicle || (!vehicle.vrn && !vehicle.vin)) {
          return <CenterFlex>-</CenterFlex>;
        }
        return <CenterFlex>{vehicle.vrn && vehicle.vrn?.length > 0 ? vehicle.vrn : vehicle.vin}</CenterFlex>;
      },
    },
  ];

  const b2bHistoryColumns = [
    {
      title: t('common.garage_name', 'Garage name'),
      dataIndex: 'clientName',
      key: 'clientName',
      render: function renderGarageName(clientName: string | undefined) {
        return <CenterFlex>{clientName}</CenterFlex>;
      },
    },
    {
      title: t('common.RRF_code', 'RRF Code'),
      dataIndex: 'clientRrfCode',
      key: 'clientRrfCode',
      render: function renderRRFCode(clientRrfCode: string | undefined) {
        return <CenterFlex>{clientRrfCode}</CenterFlex>;
      },
    },
    {
      title: t('login.select_profile.ipn', 'IPN'),
      dataIndex: 'creatorIpn',
      key: 'creatorIpn',
      render: function renderCreatorIpn(creatorIpn: string | undefined) {
        return <CenterFlex>{creatorIpn}</CenterFlex>;
      },
    },
    {
      title: t('estimate.estimate_status', 'Estimate status'),
      dataIndex: 'estimateStatus',
      key: 'estimateStatus',
      render: function renderEstimateStatus(estimateStatus: string | undefined) {
        return <CenterFlex>{estimateStatus}</CenterFlex>;
      },
    },
  ];

  const getColumns = () => {
    if (isB2B) {
      return b2bHistoryColumns;
    }
    return b2cHistoryColumns;
  };

  return [
    {
      dataIndex: 'estimateId',
      key: 'deleteEstimate',
      render: function renderTrashIcon(estimateId: string) {
        return (
          <StopPropagation>
            <TrashAltButton size={16} onClick={() => deleteEstimate(estimateId)} hoverColor={'error'} />
          </StopPropagation>
        );
      },
    },
    {
      title: t('estimate.estimate_number', 'Estimate number'),
      dataIndex: 'sequenceNumber',
      key: 'estimateNumber',
    },
    ...getCondArrayItem(
      isDMSAvailable && {
        title: t('estimate.repair_order_number', 'Repair order number'),
        dataIndex: 'dmsOrderNumber',
        key: 'dmsOrderNumber',
        render: function renderDmsNumber(dmsOrderNumber: string | undefined) {
          return (
            dmsOrderNumber && (
              <Flex>
                <Icon IconComponent={DMSImportIcon} size={29} mr={10} color={theme.color.blue_dark_2} noPointer />
                {dmsOrderNumber}
              </Flex>
            )
          );
        },
      },
    ),
    ...getColumns(),
  ];
};

const HistoryTab = ({ switchToEstimateTab }: { switchToEstimateTab: () => void }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isB2B = useIsB2B();
  const estimateHistorySearchStatus = useSelector(getEstimateHistorySearchStatus);
  const catalogVehicleKey = useSelector(getLastSearchedVehicleKey);
  const search = useSelector(getEstimateHistorySearch);
  const [estimateIdBeingDeleted, setEstimateIdBeingDeleted] = useState<string | undefined>(undefined);
  const { loadMore, hasMore, currentData } = useFetchEstimateHistory();
  const isDMSAvailable = useSelector((state: RootState) => getAvailableDMSService(state, 'ESTIMATE'));

  useEffect(() => {
    if (estimateIdBeingDeleted) {
      notifyTop(
        'info',
        <Trans i18nKey={'estimate.history.cancel'}>{'The estimate has been removed from the list.'}</Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            dispatch(cancelEstimateDeletion({ estimateId: estimateIdBeingDeleted }));
          }}
        >
          {t('estimate.history.action.cancel', 'Cancel')}
        </NotificationLink>,
      );
      setEstimateIdBeingDeleted(undefined);
    }
    /* eslint-disable-next-line */
  }, [estimateIdBeingDeleted]);

  function onChangeDebounced(val: string) {
    const trimmedVal = val.trim();
    if (trimmedVal.length > 2 || isEmpty(trimmedVal)) {
      const value = isEmpty(trimmedVal) ? undefined : trimmedVal;
      dispatch(setEstimateHistorySearch(value));
      dispatch(resetEstimateHistory());
      dispatch(fetchEstimateHistoryRequest());
    }
  }

  const deleteEstimate = (estimateId: string) => {
    setEstimateIdBeingDeleted(estimateId);
    dispatch(deleteEstimateRequest({ estimateId }));
  };

  const dataColumns = historyTableColumns(t, deleteEstimate, isDMSAvailable, isB2B);

  const getSearchPlaceholder = () => {
    if (isB2B) {
      return t(
        'estimate.history.search_by_estimate_garage_rrf_ipn',
        'Search by garage name, RRF code, IPN or estimate number',
      );
    }
    return t(
      'estimate.history.search_by_vin_vrn_client_name_estimate_number',
      'Search by VRN, VIN, client name or estimate number',
    );
  };

  return (
    <SHistoryTab>
      <SSearchInput
        onChangeDebounced={onChangeDebounced}
        isLoading={isLoading(estimateHistorySearchStatus)}
        placeholder={getSearchPlaceholder()}
        initialValue={search}
        bordered
        size={'large'}
      />
      <MarginBox mt={40} />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <STable
          columns={dataColumns}
          dataSource={currentData}
          pagination={false}
          rowKey={(row) => row.estimateId}
          loading={isLoading(estimateHistorySearchStatus)}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.vehicle !== undefined && catalogVehicleKey !== record.vehicle.vehicleKey) {
                  fetchVehicleFunction(
                    dispatch,
                    record.vehicle.vehicleKey,
                    false,
                    record.vehicle.country,
                    record.vehicle.catalogSource,
                    undefined,
                    record.vehicle.iamVehicle?.versionCode,
                  );
                }

                dispatch(setCurrentEstimateId(record.estimateId));
                history.push(`${ROUTER_ESTIMATE}`);
                switchToEstimateTab();
              },
            };
          }}
        />
      </InfiniteScroll>
    </SHistoryTab>
  );
};

export default HistoryTab;
